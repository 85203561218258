<script>
export default {
  head: {
    title: 'Lancer ma communauté',
  },
};
</script>

<template>
  <div>
    <section
      class="
        box is-primary has-background-primary
        has-text-centered has-text-white p-10
      "
    >
      <h1 class="title has-text-white mb-10">
        Bienvenue dans votre communauté !
      </h1>
      <p class="subtitle has-text-white">
        Pour permettre à vos apprenants de trouver un appui,
        pour enseigner autrement, ou pour mettre en place des mécaniques de jeux...
        Il y a 1000 bonnes raisons de créer une communauté autour de vos formations !<br>
        Suivez le guide 👇
      </p>
    </section>

    <section class="box p-0 is-clipped">
      <div class="image is-16by9">
        <iframe class="pabsolute-0 w-full h-full" src="https://www.youtube.com/embed/s_D1Svbxk3Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
      </div>
    </section>

    <section class="columns">
      <article class="column is-4">
        <div class="box content">
          <h2>Étape 1</h2>
          <p>
            Complétez la page d'accueil de votre communauté
          </p>
          <a href="https://aide.teachizy.fr/fr/article/comment-personnaliser-le-texte-daccueil-de-la-communaute-6kjsmr" target="_blank">Voir plus ></a>
        </div>
      </article>
      <article class="column is-4">
        <div class="box content">
          <h2>Étape 2</h2>
          <p>
            Créez vos premiers Espaces & Collections
          </p>
          <a href="https://aide.teachizy.fr/fr/article/comment-creer-et-parametrer-un-espace-fkjni1" target="_blank">Voir plus ></a>
        </div>
      </article>
      <article class="column is-4">
        <div class="box content">
          <h2>Étape 3</h2>
          <p>
            Invitez vos apprenants à rejoindre vos Espaces
          </p>
          <a href="https://aide.teachizy.fr/fr/article/comment-inviter-un-apprenant-a-rejoindre-ma-communaute-14mxk58" target="_blank">Voir plus ></a>
        </div>
      </article>
    </section>
    <section class="box content">
      <h2 class="has-text-success has-text-centered mt-4 mb-10">
        Pour aller plus loin...  📚
      </h2>
      <p class="has-text-weight-bold has-text-centered mt-10 mb-4">
        👉 Paramétrer ma communauté Teachizy
      </p>
      <ul>
        <li>Comment puis-je organiser ma communauté en créant des collections ? <a href="https://aide.teachizy.fr/fr/article/comment-puis-je-organiser-ma-communaute-en-creant-des-collections-1bi6x51/" target="_blank">Consulter ></a></li>
        <li>Comment lancer efficacement ma communauté auprès de mes apprenants ? <a href="https://www.teachizy.fr/blog/comment-lancer-efficacement-ma-communaute-aupres-de-mes-apprenants" target="_blank">Consulter ></a></li>
        <li>Quel sonts les droits des différents membres de mon équipe sur la communauté ? <a href="https://aide.teachizy.fr/fr/article/quel-sonts-les-droits-des-differents-membre-de-mon-equipe-sur-la-communaute-8ypv6" target="_blank">Consulter ></a></li>
      </ul>
      <p class="has-text-weight-bold has-text-centered mt-10 mb-4">
        👉 Gérer mes espaces et collections
      </p>
      <ul>
        <li>Comment personnaliser un espace ? <a href="https://aide.teachizy.fr/fr/article/comment-puis-je-personnaliser-lapparence-de-ma-communaute-nl9l1e" target="_blank">Consulter ></a></li>
        <li>Comment modifier un espace ? <a href="https://aide.teachizy.fr/fr/article/comment-modifier-un-espace-wksh0w" target="_blank">Consulter ></a></li>
        <li>Dans quel statut puis-je mettre mon espace ? <a href="https://aide.teachizy.fr/fr/article/dans-quel-statut-puis-je-mettre-mon-espace-fqng96" target="_blank">Consulter ></a></li>
      </ul>
      <p class="has-text-weight-bold has-text-centered mt-10 mb-4">
        👉 Animer et modérer ma communauté
      </p>
      <ul>
        <li>Comment créer, épingler et modifier une publication ? <a href="https://aide.teachizy.fr/fr/article/comment-creer-epingler-et-modifier-une-publication-xyyhia" target="_blank">Consulter ></a></li>
        <li>À quel rythme publier dans mes espaces et quels types de contenus ? <a href="https://www.teachizy.fr/blog/a-quel-rythme-et-quoi-publier-dans-ma-communaute" target="_blank">Consulter ></a></li>
        <li>Comment puis je modérer les contributions de mes membres ? <a href="https://aide.teachizy.fr/fr/article/comment-puis-je-moderer-les-contributions-de-mes-membres-190auid" target="_blank">Consulter ></a></li>
      </ul>
      <p class="has-text-weight-bold has-text-centered mt-10 mb-4">
        👉 Gérer les membres de ma communauté
      </p>
      <ul>
        <li>Comment inviter un.e membre à un rejoindre un espace ? <a href="https://aide.teachizy.fr/fr/article/comment-inviter-un-apprenant-a-rejoindre-ma-communaute-14mxk58" target="_blank">Consulter ></a></li>
        <li>Comment un.e membre peut-il modifier son pseudonyme et sa photo ? <a href="https://aide.teachizy.fr/fr/article/comment-une-membre-peut-il-modifier-son-pseudonyme-et-sa-photo-ogx5r9" target="_blank">Consulter ></a></li>
        <li>Comment générer des interactions sur ma communauté ? <a href="https://www.teachizy.fr/blog/comment-generer-des-interactions-sur-ma-communaute" target="_blank">Consulter ></a></li>
      </ul>
    </section>
  </div>
</template>
